/* eslint-disable camelcase */

import React from 'react'
import { Badge } from 'react-bootstrap'
import { useGetListingQuery } from '../../../../components/api'
import Countdown from '../../../../components/Countdown'
import { SimpleTabs } from '../../../../components/Form'
import Layout from '../../../../components/Layout'
import ListingStatus from '../../../../components/ListingStatus'
import BuyerBiddingActivityTab from '../../../../components/viewListingTabs/buyerBiddingActivityTab'
import BuyerViewTab from '../../../../components/viewListingTabs/buyerViewTab'

const Page = (props) => {
  const { listingId } = props
  const listingQuery = useGetListingQuery({ listingId, isVendor: false }) // N.B. from this page, we are always the buyer!

  const { data: { global_listing_number = '', status = null, sale_type_selection = null } = {} } = listingQuery
  const listingIsOpen = status === 'open'
  const isTender = sale_type_selection === 'tender'
  const disabled = !(listingIsOpen || (isTender && status !== 'draft'))

  // react-state
  const [page, setPage] = React.useState(0)

  return <Layout>

        <Layout.Title showBackButton>
            <div className="d-flex align-items-center">
              <h5>List Number: {global_listing_number}</h5>
              { listingQuery.isSuccess && <ListingStatus status={listingQuery.data.status} /> }
            </div>
        </Layout.Title>

        <Layout.CardGrey>

            <SimpleTabs elements={[
              { label: 'Listing', onClick: () => setPage(0) },
              { label: listingIsOpen ? 'Bid Activity' : 'Bid Activity (Ended)', onClick: () => setPage(1), disabled }
              // { label: 'Product Manifest', onClick: () => setPage(2) }
            ]} selectedIndex={page} size="md">

              {/* show time remaining */}
              <div className="d-flex flex-grow-1 align-items-center justify-content-end">
                {listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'disabled' && (
                  <Badge variant="outline-danger" className="countdown countdown-lg">
                    <span className="fas fa-clock"></span> End time: <span style={{ display: 'inline-block', minWidth: '55px' }}>
                      <Countdown toDate={new Date(listingQuery.data.end_time)} showOnEnd='Ended' />
                    </span>
                  </Badge>
                ) }
              </div>

            </SimpleTabs>
            {/* Buyer */}
            { page === 0 && <BuyerViewTab {...props} />}
            { page === 1 && <BuyerBiddingActivityTab {...props} />}

        </Layout.CardGrey>

    </Layout>
}

export default Page
