
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { Button } from 'react-bootstrap'
import { NOOP } from './utils'

const defaultToolbarStyles = {
  iconButton: {
  }
}
const MuiToolbarSubmit = ({
  onUpload = NOOP,
  onSubmit = NOOP,
  disabled = false,
  ...props
}) => {
  return (
        <>
          <Tooltip title={'Submit'}>
            <Button
              size="sm"
              variant="primary"
              className="ml-2"
              onClick={onSubmit}
              disabled={onSubmit === NOOP || disabled}
              {...props}
              >Submit</Button>
          </Tooltip>
        </>
  )
}

export default withStyles(defaultToolbarStyles, { name: 'MuiToolbarSubmit' })(MuiToolbarSubmit)
