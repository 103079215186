/* eslint-disable camelcase */

import Chip from '@material-ui/core/Chip'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import parse from 'html-react-parser'
import moment from 'moment'
import { TableFilterList } from 'mui-datatables'
import React from 'react'
import { Alert, Badge, Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import { hideNull } from '../../components/hideNull'
import filePng from '../../images/file.png'
import { useUpdateTermsAndConditions } from '../../utils/api'
import { useGetCountriesQuery, useGetCurrenciesQuery, useGetListingQuery, useGetListingStatusesQuery, useGetuserDetailsQuery } from '../api'
import { getImeiReport, useBuyerGetListingLotsQuery, useGetListingLotSkusQuery } from '../api/BuyerApi'
import { downloadGradingStandards } from '../api/DownloadsApi'
import LotNavButtons from '../biddingActivityTabs/LotNavButtons'
import Countdown from '../Countdown'
import { SimpleTabs } from '../Form'
import MUIDataTable from '../MuiDatatableUtils'
import MuiToolbarDownloadIMEI from '../MuiToolbarDownloadIMEI'
import { buyerNavTo } from '../navigation'
import Spinner from '../spinner'
import { getLabelFromArray, getLabelFromObject, OPTS_AWARDLEVEL, OPTS_BIDLEVEL, OPTS_BIDSMANDATORY, OPTS_SALETYPESELECTION, OPTS_SHOWASKING, OPTS_TIMEEXTENSION } from '../static'
import { triggerDownloadOfCSV } from '../utils'
import { useWatchListingSocket, WebSocketStatus } from '../websocket'

const CustomChip = ({ label, onDelete }) => {
  return (
        <Chip
            variant="outlined"
            color="secondary"
            label={label}
            onDelete={onDelete}
        />
  )
}

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />
}

/**
 * Returns true if awardLevelSelection is 'award_sku.
 */
const isAwardLevelSelectionASku = (awardLevelSelection) => awardLevelSelection === 'award_sku'

const Page = (props) => {
  const { listingId } = props

  // state

  const [wsstatus, setWsstatus] = React.useState('connecting')
  const [selectedLotId, setSelectedLotId] = React.useState(null)
  const [selectedLot, setSelectedLot] = React.useState({})
  const [showAdditionalNotesModal, setShowAdditionalNotesModal] = React.useState(false)
  const [showSamplePhotosModal, setShowSamplePhotosModal] = React.useState(false)
  const [showBiddingIncrementsModal, setShowBiddingIncrementsModal] = React.useState(false)
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = React.useState(false)
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = React.useState(false)
  const [termAndConditionUpdated, setTermAndConditionUpdated] = React.useState(false)

  // query
  const userDetailsQuery = useGetuserDetailsQuery()
  const { data: { _isVendor = false } = {} } = userDetailsQuery
  const listingQuery = useGetListingQuery({ listingId, isVendor: false }) // N.B. from this page, we are always the buyer!
  const { data: listingQueryData = {}, data: { sale_type_selection } = {} } = listingQuery
  const {
    listing_lots: listingLots = [],
    award_level_selection: awardLevelSelection = null
  } = listingQueryData
  const isTender = sale_type_selection === 'tender'
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId: selectedLotId, listingLots, isVendor: false })
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot
  const statusesQuery = useGetListingStatusesQuery()
  const countriesQuery = useGetCountriesQuery()
  const currenciesQuery = useGetCurrenciesQuery()

  const updateTermsAndCondition = useUpdateTermsAndConditions()

  const listingLotsQuery = useBuyerGetListingLotsQuery({ listingId, listingLots })
  const { data: listingLotsQueryData = [] } = listingLotsQuery

  const getLotRow = (meta) => {
    return listingLotsQueryData[meta.currentTableData[meta.rowIndex].index]
  }
  const getSkuRow = (meta) => {
    return listingLotSkus[meta.currentTableData[meta.rowIndex].index]
  }

  //
  React.useEffect(() => {
    if (listingQuery.isSuccess && listingQueryData && !termAndConditionUpdated) {
      if (listingQueryData.terms_and_conditions && listingQueryData.acceptedTermsAndConditions === null) {
        setShowTermsAndConditionsModal(true)
      }
    }
  }, [listingQuery, listingQueryData, termAndConditionUpdated])
  // connect for socket updates...
  useWatchListingSocket({ listingId, handleStatusUpdate: setWsstatus, refreshEntireListing: true, isVendor: false })

  // NOTE downloading of IMEI report
  const [imeiReportLoading, setImeiReportLoading] = React.useState(false)

  const handleUpdateTermsAndConditions = () => {
    setTermAndConditionUpdated(true)
    setShowTermsAndConditionsModal(false)
    updateTermsAndCondition.mutate({ listingId })
  }
  const downloadImeiReport = (listingReference, lotId = 'all') => {
    setImeiReportLoading(true)
    getImeiReport({ listingReference, lotId }).then((data) => {
      // console.debug('imei report data received = ' + JSON.stringify(data, null, 2))
      triggerDownloadOfCSV(`Listing${listingReference}-Lot${lotId}-ImeiReport.csv`, [
        'Lot',
        'Make',
        'Model',
        'Memory',
        'Colour',
        'Carrier',
        'Kit',
        'SimLock',
        'IdLock',
        'RegionLock',
        'Grade',
        'Description',
        'IMEI',
        'Bid'
      ], data.listing_lot_skus.map(sku => [
        hideNull(sku.lot_number),
        hideNull(sku.make),
        hideNull(sku.model),
        hideNull(sku.memory),
        hideNull(sku.colour),
        hideNull(sku.carrier),
        hideNull(sku.kit),
        hideNull(sku.sim_lock),
        hideNull(sku.id_lock),
        hideNull(sku.region_lock),
        hideNull(sku.grade),
        hideNull(sku.sku_description),
        hideNull(sku.imei),
        hideNull(sku.buyer_bid)
      ]))
      setImeiReportLoading(false)
    }).catch((err) => {
      console.error(err.message, { stack: err.stack })
      setImeiReportLoading(false)
    })
  }

  // ANCHOR LOT columns
  const columnsLot = [
    listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled'
      ? {
          label: 'End Time',
          name: 'end_time',
          options: { customBodyRender: (value) => value ? <Countdown className="text-nowrap" toDate={new Date(value)} /> : 'No end time?' }
        }
      : null,
    // { label: 'Id', name: 'id' },
    { label: 'Lot', name: 'lot_number' },
    { label: 'Description', name: 'lot_description' },
    { label: 'Quantity', name: 'quantity' },
    { label: 'Your Bid', name: 'buyer_bid', options: { customBodyRender: (value) => value === null || value === 0 ? null : `$${parseFloat(value).toLocaleString()}` } },
    {
      label: 'Award',
      name: 'award_status',
      options: {
        customBodyRender: (award_status) => {
          if (typeof award_status === 'string' && award_status.startsWith('accept')) return <Badge variant="outline-success">Won</Badge>
          if (typeof award_status === 'string' && award_status.startsWith('decline')) return <Badge variant="outline-danger">Lost</Badge>
        }
      }
    }, isTender
      ? null
      : (
          {
            label: 'Unit Price',
            name: 'id',
            options: {
              customBodyRender: (lotId, meta) => {
                const lot = getLotRow(meta)
                if (lot.award_status !== 'accepted') return null
                return (lot.unit_price_status === 'awaiting'
                  ? <Button size="sm" variant="outline-primary" onClick={() => buyerNavTo.submitUnitPricesPage(listingId, lotId)} >Submit</Button>
                  : lot.unit_price_status === 'received'
                    ? <Button size="sm" variant="outline-primary" onClick={() => buyerNavTo.submitUnitPricesPage(listingId, lotId)} >View</Button>
                    : null)
              }
            }
          }),
    {
      label: 'IMEI',
      name: 'IMEI',
      options: {
        customBodyRender: (value, meta) => {
          const lot = getLotRow(meta)
          if (lot.award_status !== 'accepted') return null
          return <Button size="sm" variant="outline-primary" disabled={imeiReportLoading} onClick={() => downloadImeiReport(listingId, lot.lot_id)}>{imeiReportLoading ? <Spinner /> : 'Download'}</Button>
        }
      }
    }
  ].filter(f => f !== null)

  // ANCHOR SKU columns
  const columnsSku = [
    listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled'
      ? {
          label: 'End Time',
          name: 'end_time',
          options: { customBodyRender: (value) => value ? <Countdown className="text-nowrap" toDate={new Date(value)} /> : 'No end time?' }
        }
      : null,
    // { label: 'Id', name: 'id' },
    { label: 'Lot', name: 'listing_lot_number' },
    {
      label: 'Description',
      name: 'sku_description'
      // options: {
      //   customBodyRender: (val, meta) => skuToDescription(getSkuRow(meta))
      // }
    },
    { label: 'Grade', name: 'grade' },
    { label: 'Quantity', name: 'qty' },
    {
      label: 'Your Bid',
      name: 'buyer_bid',
      options: {
        setCellProps: () => ({ align: 'right' }),
        customBodyRender: (value) => value === null || value === 0 ? null : `$${parseFloat(value).toLocaleString()}`
      }
    },
    {
      label: 'Subtotal',
      name: 'buyer_bid',
      options: {
        setCellProps: () => ({ align: 'right' }),
        customBodyRender: (value, meta) => value === null || value === 0 ? null : `$${(parseFloat(value) * getSkuRow(meta).qty).toLocaleString()}`
      }
    },
    {
      label: 'Award',
      name: 'award_status',
      options: {
        customBodyRender: (x, meta) => {
          const sku = getSkuRow(meta)
          if (sku.buyer_bid) {
            if (typeof sku.award_status === 'string' && sku.award_status.startsWith('accept')) return <Badge variant="outline-success">Won</Badge>
            if (typeof sku.award_status === 'string' && sku.award_status.startsWith('decline')) return <Badge variant="outline-danger">Lost</Badge>
          }
        }
      }
    }
    // todo If exists, label should be "View"
  ].filter(f => f !== null)

  return <>

              <div className="card-body rounded-soft bg-white">
                {/* show spinner if loading, otherwise show content */}
                { listingQuery.isLoading || statusesQuery.isLoading || countriesQuery.isLoading || currenciesQuery.isLoading
                  ? <div className="d-flex justify-content-center p-5"><Spinner /></div>
                  : listingQuery.isError && listingQuery.error
                    ? (<Alert variant="danger" className="my-4">{listingQuery.error.message}</Alert>)
                    : listingQuery.isSuccess && (
                    <div className="container-fluid mb-4">
                      <Row>

                        {/* details */}
                        <Col md={12} lg={5}>
                          <div className="rh-bodyheader">Details</div>
                          <ListGroup>
                            {[
                              ['End Time', <Countdown key="endtime" toDate={new Date(listingQuery.data.end_time)} />],
                              ['Reference', listingQuery.data.listing_reference],
                              ['Origin', getLabelFromArray(countriesQuery.data, listingQuery.data.country)],
                              ['Currency', getLabelFromArray(currenciesQuery.data, listingQuery.data.currency)],
                              ['Total Lots', (typeof listingQuery.data.total_lot_count === 'undefined' || listingQuery.data.total_lot_count === null) ? '-' : (listingQuery.data.total_lot_count).toLocaleString()],
                              ['Total Units', (typeof listingQuery.data.total_item_count === 'undefined' || listingQuery.data.total_item_count === null) ? '-' : (listingQuery.data.total_item_count).toLocaleString()]
                            ].map((val) => {
                              if (val) {
                                const [label, value] = val
                                return <ListGroup.Item key={label}><b>{label}</b>{value}</ListGroup.Item>
                              }
                              return undefined
                            }
                            )}
                          </ListGroup>
                        </Col>

                        {/* format */}
                        <Col md={12} lg={5}>
                          <div className="rh-bodyheader">Format</div>
                          <ListGroup>
                            {isTender
                              ? (
                                  [
                                    ['Sale Type', getLabelFromObject(OPTS_SALETYPESELECTION, listingQuery.data.sale_type_selection)],
                                    ['Award Level', getLabelFromObject(OPTS_AWARDLEVEL, listingQuery.data.award_level_selection)],
                                    ['Bid Level', getLabelFromObject(OPTS_BIDLEVEL, listingQuery.data.bid_level_selection)],
                                    ['Bids Mandatory on All Lines', getLabelFromObject(OPTS_BIDSMANDATORY, listingQuery.data.bids_mandatory_selection)]
                                  ].map(([label, value]) =>
                              <ListGroup.Item key={label}><b>{label}</b>{value}</ListGroup.Item>
                                  ))
                              : ([
                                  ['Sale Type', getLabelFromObject(OPTS_SALETYPESELECTION, listingQuery.data.sale_type_selection)],
                                  ['Time Extension', getLabelFromObject(OPTS_TIMEEXTENSION, listingQuery.data.time_extension_selection)],
                                  ['Award Level', getLabelFromObject(OPTS_AWARDLEVEL, listingQuery.data.award_level_selection)],
                                  ['Bid Level', getLabelFromObject(OPTS_BIDLEVEL, listingQuery.data.bid_level_selection)],
                                  ['Show Asking Bid', getLabelFromObject(OPTS_SHOWASKING, listingQuery.data.show_asking_bid_selection)],
                                  ['Bid Increments', <Button key="foo" variant="outline-primary" size="sm" onClick={() => setShowBiddingIncrementsModal(true)}>View</Button>],
                                  ['Bids Mandatory on All Lines', getLabelFromObject(OPTS_BIDSMANDATORY, listingQuery.data.bids_mandatory_selection)]
                                ].map(([label, value]) =>
                              <ListGroup.Item key={label}><b>{label}</b>{value}</ListGroup.Item>
                                ))}
                          </ListGroup>
                        </Col>

                        {/* supplements */}
                        { listingQuery.isSuccess && (
                          <Col md={12} lg={2}>
                            <div className="rh-bodyheader">Supplements</div>
                            {/* product manifest */}
                            <div>
                              <Button
                                variant="outline-primary"
                                className="shadow mb-3"
                                onClick={() => buyerNavTo.productManifestPage(listingId)}
                                // disabled={!listingQuery.data.product_manifest_file_url}
                                // onClick={() => download(listingQuery.data.product_manifest_file_url)}
                              >
                                Product Manifest
                              </Button>
                            </div>
                            {/* communications */}
                            <div>
                            <Button
                              variant="outline-primary"
                              className="shadow mb-3"
                              onClick={() => buyerNavTo.productCommunications(listingId)}
                              // disabled={!listingQuery.data.product_manifest_file_url}
                              // onClick={() => download(listingQuery.data.product_manifest_file_url)}
                            >
                              Communications
                            </Button>
                          </div>
                            {/* listing notes
                            <div>
                              <Button
                                variant="outline-primary"
                                className="shadow mb-3"
                                disabled={typeof listingQuery.data.buyer_notes !== 'string' || listingQuery.data.buyer_notes.trim().length === 0}
                                onClick={() => setShowAdditionalNotesModal(true)}
                            >
                              Listing Notes
                            </Button>
                            </div>
                             */}
                            {/* terms and conditions */}
                            <div>
                              <Button
                                variant="outline-primary"
                                className="shadow mb-3"
                                disabled={typeof listingQuery.data.terms_and_conditions !== 'string' || listingQuery.data.terms_and_conditions.trim().length === 0}
                                onClick={() => setShowTermsAndConditionsModal(true)}
                            >
                              Terms and Conditions
                            </Button>
                            </div>

                            {/* sample phots */}
                            { !listingQuery.data.sample_photo_files
                              ? <Button
                              variant="outline-primary"
                              className="shadow mb-3"
                              disabled={true}
                              onClick={() => setShowSamplePhotosModal(true)}
                          >
                          Attachments
                          </Button>
                              : <div>
                            <Button
                              variant="outline-primary"
                              className="shadow mb-3"
                              disabled={ listingQuery.data.sample_photo_files.filter(p => p !== 'undefined').length === 0 }
                              onClick={() => setShowSamplePhotosModal(true)}
                          >
                          Attachments
                          </Button>
                          </div>
                        }
                            {/* grading standard */}
                            <div><Button variant="outline-primary" className="shadow mb-3" onClick={() => downloadGradingStandards()}>Grading Standard</Button></div>
                          </Col>
                        )}

                      </Row>

                      {/* summary table */}
                      <div className="rh-bodyheader border-bottom mt-3">Summary</div>
                      <SimpleTabs elements={[{ label: 'Overview' }]} selectedIndex={0} size="sm" />
                      <div className="mt-3">

                      {/* lot buttons */}
                      { isAwardLevelSelectionASku(awardLevelSelection) && <LotNavButtons
                        listingQuery={listingQuery}
                        selectedLotId={selectedLotId}
                        setSelectedLotId={setSelectedLotId}
                        setSelectedLot={setSelectedLot}
                      />}

                        <WebSocketStatus status={wsstatus} />

                        {/* ANCHOR SKU table */}
                        { isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                        { isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isError && listingLotSkusQuery.error && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                        { isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isSuccess && <MUIDataTable
                                columns={columnsSku}
                                data={listingLotSkus}
                                options={{
                                  selectableRows: 'none',
                                  pagination: true,
                                  jumpToPage: false,
                                  rowsPerPage: 100,
                                  print: false,
                                  search: true,
                                  download: true,
                                  sort: false,
                                  filter: true,
                                  viewColumns: true,
                                  elevation: 0,
                                  components: {
                                    TableFilterList: CustomFilterList
                                  },
                                  customToolbar: () => {
                                    const someWon = listingLotSkus.some(sku => {
                                      return typeof sku.award_status === 'string' && sku.award_status.startsWith('accept')
                                    })
                                    return <MuiToolbarDownloadIMEI onDownloadIMEI={() => downloadImeiReport(listingId, 'all')} disabled={!someWon} />
                                  },
                                  setTableProps: () => ({ size: 'small' }),
                                  customTableBodyFooterRender: function(opts) {
                                    // calculate total across all skus...
                                    const total = listingLotSkus.reduce((accu, item) => {
                                      accu = accu + (item.qty * item.buyer_bid)
                                      return accu
                                    }, 0)

                                    return (
                                      <TableFooter >
                                        <TableRow>
                                          {opts.selectableRows !== 'none' ? <TableCell /> : null}
                                          {opts.columns.map((col, index) => {
                                            if (col.display === 'true') {
                                              if (col.label === 'Subtotal') {
                                                return (<TableCell key={index} align="right"><b>{`$${parseFloat(total).toLocaleString()}`}</b></TableCell>)
                                              } else if (col.label === 'Your Bid') {
                                                return (<TableCell key={index} align="right"><b>Total:</b></TableCell>)
                                              } else {
                                                return <TableCell key={index} />
                                              }
                                            }
                                            return null
                                          })}
                                        </TableRow>
                                      </TableFooter>
                                    )
                                  }
                                }}
                                />}

                        {/* ANCHOR LOT table */}
                        { !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                        { !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                        { !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isSuccess && <MUIDataTable
                                columns={columnsLot}
                                data={listingLotsQueryData}
                                options={{
                                  selectableRows: 'none',
                                  pagination: true,
                                  jumpToPage: false,
                                  rowsPerPage: 100,
                                  print: false,
                                  search: true,
                                  download: true,
                                  sort: false,
                                  filter: true,
                                  viewColumns: true,
                                  elevation: 0,
                                  components: {
                                    TableFilterList: CustomFilterList
                                  },
                                  setTableProps: () => ({ size: 'small' })
                                }}
                                />}

                        </div>
                    </div>

                    )
                }

          {/* ANCHOR Debug */}
          { process.env.NODE_ENV !== 'production' && <pre>listingLotsQuery={JSON.stringify(listingLotsQueryData, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingQuery={JSON.stringify(listingQuery.data, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingLotSkusQuery={JSON.stringify(listingLotSkusQuery, null, 2)}</pre>}

              </div>

        {/* ANCHOR Modals */}

        {/* Listing Notes
        <Modal show={showAdditionalNotesModal} onHide={() => setShowAdditionalNotesModal(false)}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Listing Notes</h5>
                <div className="py-3">
                  {listingQuery.isSuccess && listingQuery.data && typeof listingQuery.data.buyer_notes === 'string' && parse(listingQuery.data.buyer_notes)}
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={() => setShowAdditionalNotesModal(false)} variant="outline-primary">Close</Button>
                    { _isVendor && <Button type="button" onClick={() => vendorNavTo.editListingPage(listingId, { page: CREATE_LISTING_TAB_PAGES.supplements })} variant="primary">Edit</Button> }
                </div>
            </Modal.Body>
        </Modal>
        */}
        {/* Terms and Conditions */}
        <Modal show={showTermsAndConditionsModal} onHide={() => setShowTermsAndConditionsModal(false)} backdrop="static" size="xl" keyboard={false}
        className={'custom-backdrop-filter'}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Terms and Conditions</h5>
                <div className="py-3 border p-3">
                  {listingQuery.isSuccess && listingQuery.data && typeof listingQuery.data.terms_and_conditions === 'string' && parse(listingQuery.data.terms_and_conditions)}
                </div>
                <Row>
              <Col className={`mx-3 mt-3 px-2 mb-3 py-1 ${listingQuery?.data?.acceptedTermsAndConditions !== null || termsAndConditionsChecked ? 'highlighted-checkbox' : ''}`}>
                <Form.Check type="checkbox" id="cb-1">
                  <Form.Check.Input
                    type="checkbox"
                    checked={listingQuery?.data?.acceptedTermsAndConditions !== null || termsAndConditionsChecked}
                    onChange={e => {
                      setTermsAndConditionsChecked(e.target.checked)
                    }}
                  />
                  <Form.Check.Label className="cursor-pointer ml-2">I agree to the terms and conditions </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
            {listingQuery.isSuccess && listingQuery.data && listingQuery.data.acceptedTermsAndConditions !== null && <div className="mb-4" style={{ color: 'grey' }}>Terms and Conditions agreed on: {moment(listingQuery?.data?.acceptedTermsAndConditions).format('DD/MM/YYYY HH:mma')} </div>}

                <div className="d-flex justify-content-between mt-3">
                    <Button type="button" onClick={() => {
                      if (listingQuery?.data?.acceptedTermsAndConditions === null) { buyerNavTo.viewBuyerListing() } else {
                        setShowTermsAndConditionsModal(false)
                      }
                    }} variant="outline-primary">Close</Button>
                    <Button variant="primary" type="button" onClick={() => { handleUpdateTermsAndConditions() }} disabled={!termsAndConditionsChecked } hidden={listingQuery?.data?.acceptedTermsAndConditions !== null}> Continue </Button>
                </div>
            </Modal.Body>
            { process.env.NODE_ENV !== 'production' && <pre>termsAndConditions={JSON.stringify(termsAndConditionsChecked, null, 2)}</pre>}
            { process.env.NODE_ENV !== 'production' && <pre>termsAndConditions={JSON.stringify(showTermsAndConditionsModal, null, 2)}</pre>}
        </Modal>

        {/* Sample Photos */}
        <Modal dialogClassName="sample-photo-modal-width" show={showSamplePhotosModal} onHide={() => setShowSamplePhotosModal(false)}>
            <Modal.Body>
                <h5 className="m-0 mb-2">Attachments</h5>
                <div className="py-3">
                  {listingQuery.isSuccess && listingQuery.data && listingQuery.data.sample_photo_files && listingQuery.data.sample_photo_files[0] !== 'undefined' &&
                  <>
                  <Carousel className="carousel slide carousel-dark" id="custom-sample-photo-slider"
                  >
                  {listingQuery.data.sample_photo_files.filter(p => p !== 'undefined').map(filteredPhotoFiles => (
                    <Carousel.Item key={filteredPhotoFiles.originalname}>
                    {filteredPhotoFiles.mimetype.slice(0, 5) === 'image'
                      ? <img
                  style={{ objectFit: 'contain', height: 60 + 'vh' }}
                  className="d-block w-100"
                  src={'data:' + filteredPhotoFiles.mimetype + ';base64,' + filteredPhotoFiles.base64content}
                  alt="First slide"
                />
                      : <div className="py-10">
                <div className="file-thumbnail mx-auto">
                  <img
                    className="border h-100 w-100 fit-cover rounded-2"
                    src={
                      filteredPhotoFiles.mimetype.slice(0, 5) ===
                      'image'
                        ? 'data:' +
                          filteredPhotoFiles.mimetype +
                          ';base64,' +
                          filteredPhotoFiles.base64content
                        : filePng
                    }
                    alt=""
                  />
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <p>
                    Download:
                    <a
                      href={
                        'data:' +
                        filteredPhotoFiles.mimetype +
                        ';base64,' +
                        filteredPhotoFiles.base64content
                      }
                      download={filteredPhotoFiles.originalname}
                    >
                      {filteredPhotoFiles.originalname}
                    </a>
                  </p>
                </div>
              </div>}
                  </Carousel.Item>
                  ))}
                </Carousel>

              </>
              }
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <Button type="button" onClick={() => setShowSamplePhotosModal(false)} variant="outline-primary">Close</Button>

                </div>
            </Modal.Body>
        </Modal>

        {/* Bid Increments */}
        <Modal show={showBiddingIncrementsModal} onHide={() => setShowBiddingIncrementsModal(false)}>
            <Modal.Body>
                <h5 className="m-0 mb-3">{isAwardLevelSelectionASku(awardLevelSelection) ? 'Bid Increment' : 'Bid Increments'}</h5>
                <div className="py-3">
                  <MUIDataTable
                    columns={[{ label: 'Bid Range', name: 'range' }, { label: 'Increment', name: 'increment' }]}
                    data={
                      isAwardLevelSelectionASku(awardLevelSelection)
                        // SKU
                        ? [{ range: '$0+', increment: '$2' }]
                        // LOT
                        : [
                            { range: '$0 - $10,000', increment: '$500' },
                            { range: '$10,001 - $25,000', increment: '$1,000' },
                            { range: '$25,001 - $100,000', increment: '$2,500' },
                            { range: '$100,001 - $250,000', increment: '$5,000' },
                            { range: '$250,001 - $500,000', increment: '$10,000' },
                            { range: '$500,001 - $1,000,000', increment: '$25,000' },
                            { range: '$1,000,001+', increment: '$50,000' }
                          ]}
                    options={{
                      pagination: false,
                      jumpToPage: false,
                      rowsPerPage: 20,
                      print: false,
                      search: false,
                      download: false,
                      sort: false,
                      filter: false,
                      viewColumns: false,
                      selectableRows: 'none',
                      elevation: 0,
                      setTableProps: () => ({ size: 'small' })
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={() => setShowBiddingIncrementsModal(false)} variant="outline-primary">Close</Button>
                    { _isVendor && <Button type="button" onClick={() => {}} disabled={true} variant="primary">Edit</Button> }
                </div>
            </Modal.Body>
        </Modal>
      </>
}

export default Page
