
import debounce from 'lodash.debounce'
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { diffMs } from '../../Countdown'
import { Status } from '../../ListingStatus'

const NOOP = () => { }

/**
 * PlaceBid Input Cell.
 */

const PlaceTenderBidCell = ({
  listingId,
  listingLotId,
  listingLotSkuId,
  currency,
  listingStatus:
  status,
  buyerBid = null,
  endTime,
  onBidUpdated = NOOP
} = {}) => {
  // debouncing
  const debouncedOnBidUpdated = React.useMemo(() => debounce(onBidUpdated, 300), [onBidUpdated])

  // state
  const [bidAmount, setBidAmount] = React.useState('')
  const [formEnabled, setFormEnabled] = React.useState(status === Status.open)
  const [disabledMessage, setDisabledMessage] = React.useState('')

  // initialise
  React.useEffect(() => {
    if (typeof buyerBid !== 'undefined' && buyerBid !== null && buyerBid !== '' && !isNaN(parseInt(buyerBid)) && parseInt(buyerBid) > 0) {
      if (buyerBid !== bidAmount) {
        console.debug(`setting buyer bid amount '${buyerBid}'`)
        setBidAmount(buyerBid)
      }
    } else {
      console.debug(`buyer bid was not valid '${buyerBid}'`)
      // important: clears out old values
      setBidAmount('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerBid]) // ignore bidAmount changes

  // watch for updates to status
  React.useEffect(() => {
    setFormEnabled(ps => {
      if (ps === true) {
        return status === Status.open
      } else {
        return false
      }
    })
  }, [status])

  // check if we should disable form if listing has ended...
  React.useEffect(() => {
    // do immediate check to see if timer should be disabled...
    const deltaMs = diffMs(new Date(endTime))
    if (deltaMs < 0) {
      console.debug('timer reached #3, disabling form...')
      setBidAmount('')
      setFormEnabled(false)
      setDisabledMessage('Listing Lot has ended')
    } else {
      // otherwise clear timer in future...
      const timer = setTimeout(() => {
        console.debug('timer reached #4, disabling form...')
        setBidAmount('')
        setFormEnabled(false)
        setDisabledMessage('Listing Lot has ended')
      }, deltaMs)
      return () => clearTimeout(timer)
    }
  }, [endTime])

  // if bidAmount is blank (e.g. post-submit), make sure submit is disabled!
  React.useEffect(() => {
    if (bidAmount === '') {
      setDisabledMessage('Please provide a bid')
    }
  }, [bidAmount])

  // on update field...
  const updateField = value => {
    console.log(`updating field '${value}'`)
    // if blank, don't allow submitting...
    if (value.trim().length === 0) {
      debouncedOnBidUpdated(listingId, listingLotSkuId, '')
      setBidAmount('')
      setDisabledMessage('Please provide a bid')

      // if value is a valid number, allow updating the value...
    } else if (!isNaN(parseInt(value)) && parseInt(value) > 0) {
      // setTimeout(() => onBidUpdated(listingId, listingLotSkuId, value))
      debouncedOnBidUpdated(listingId, listingLotSkuId, value)
      setBidAmount(value)
    }
  }

  return <>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text style={{ height: '29px' }}>{currency}</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control

          size="sm"
          type="text"
          value={bidAmount || buyerBid}
          disabled={!formEnabled}
          title={formEnabled ? '' : disabledMessage}
          onChange={e => {
            const newBid = e.target.value
            if (bidAmount !== newBid) {
              console.log('setting bid amount', newBid)
              setBidAmount(newBid)
              updateField(newBid)
            }
          }}
          className='placebid'
          onFocus={e => e.target.select()} // select all text on focus
          placeholder={formEnabled ? 'Enter unit bid' : '' }
          inputMode="numeric"
        />
      </InputGroup>
    </>
}

export default PlaceTenderBidCell
