
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { Button } from 'react-bootstrap'
import { NOOP } from './utils'

const defaultToolbarStyles = {
  iconButton: {
  }
}
const MuiToolbarDownloadIMEI = ({
  onDownloadIMEI = NOOP,
  disabled = false
}) => {
  if (onDownloadIMEI === NOOP) {
    // don't display at all
    return undefined
  } else {
    return (
      <Tooltip title={'Download IMEI'}>
        <Button size="sm" variant="outline-primary" className="ml-2" onClick={onDownloadIMEI} disabled={disabled} >Download IMEI</Button>
      </Tooltip>)
  }
}

export default withStyles(defaultToolbarStyles, { name: 'MuiToolbarDownloadIMEI' })(MuiToolbarDownloadIMEI)
